import { Box, Button, Flex, Heading, Text } from "rebass/styled-components"
import { theme } from "../../theme"
import { ContentWrapper } from "../ContentWrapper"
import { FancyImage } from "../common/FancyImage"
import { StyledText } from "../common/StyledText"
import { data } from "./data"
import * as React from "react"
import { Line } from "../common/Line"
import { routes } from "../../page-data/routes"
import styled from "styled-components"
import { HeroImage } from "./HeroImage"

export const Hero = () => {
  return (
    <Flex pt={[2, 4]} bg={theme.colors.bgDark} data-testid="hero">
      <ContentWrapper style={{ position: "relative" }} maxWidth={1290}>
        <StyledHero flexWrap="wrap">
          <Box width={[1, 1, 2 / 5]}>
            <FancyImage
              imageElement={<HeroImage />}
              circleColor={"#DDCF54"}
              leafColor={"#CF4520"}
              style={{
                bottom: -59,
              }}
            />
          </Box>

          <Flex justifyContent="flex-end" width={[1, 1, 3 / 5]}>
            <Box mt={45} pl={[0, 0, 5]} style={{ zIndex: 1 }}>
              <Heading
                fontSize={["32px", "48px", "48px", "64px"]}
                fontWeight={500}
                maxWidth={672}
                mb={3}
                color={theme.colors.headingBgDark}
                fontFamily={"Arnhem"}
              >
                <StyledText
                  text={data.hero.heading}
                  styledFragment={data.hero.headingStyled}
                />
              </Heading>

              <Text color={"white"} opacity={0.78} mb={4} fontSize={"22px"}>
                {data.hero.subheading}
              </Text>

              <Button
                variant={"outline"}
                py={2}
                px={4}
                mb={[2, 5]}
                href={routes.storymap}
                as={"a"}
              >
                {data.hero.ctaButtonText}
              </Button>

              <Desktop>
                <Line />
                <Text
                  color={"white"}
                  opacity={0.75}
                  pb={4}
                  maxWidth={650}
                  fontSize={["16px", "16px", "18px"]}
                >
                  {data.hero.description}
                </Text>
              </Desktop>
            </Box>
          </Flex>
        </StyledHero>
      </ContentWrapper>
    </Flex>
  )
}

const StyledHero = styled(Flex)`
  flex-direction: column-reverse;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: row;
  }
`

const Desktop = styled(Box)`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    display: block;
  }
`
