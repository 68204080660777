import { Box, Flex, Heading, Text } from "rebass/styled-components"
import { CirclesMap } from "./Map"
import { data } from "./data"
import { SearchBox } from "office-ui-fabric-react"
import { theme } from "../../theme"
import { CasesData, Chart } from "./Chart"
import * as React from "react"
import styled from "styled-components"
import { ContentWrapper } from "../ContentWrapper"
import { useEffect, useMemo, useState } from "react"
import { LocalDetails } from "../common/LocalDetails"
import Autocomplete from "react-autocomplete"
import { isMatchingInput, sortStates } from "../common/autocompleteHelper"

export function WhatsGoingOnWhereILiveForm(props: {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClear: () => void
  onSelect: (value: string) => void
  casesData: CasesData
}) {
  const options = useMemo(
    () => props.casesData.sort().map(o => ({ label: o.search })),
    [props.casesData]
  )

  const [value, setValue] = useState("")

  return (
    <>
      <div>
        <Heading mt={135} fontSize={"32px"} fontFamily="Gentona-Book">
          {data.search.heading}
        </Heading>
        <Text mt={4} mb={5} fontSize={"17px"}>
          {data.search.subheading}
        </Text>
      </div>

      <InputWrapper width={[1, "457px"]}>
        {/* TODO searchbox needed just to render the icon properly, fix that */}
        <SearchBox value={""} onChange={() => {}} onClear={() => {}} />
        <i
          data-icon-name="Search"
          aria-hidden="true"
          className="ms-SearchBox-icon icon-46"
        >
          
        </i>
        <Autocomplete
          getItemValue={item => item.label}
          items={options}
          sortItems={sortStates}
          shouldItemRender={isMatchingInput}
          renderItem={(item, isHighlighted) => (
            <Box
              p={2}
              style={{
                background:
                  isHighlighted || item.label === value ? "lightgray" : "white",
              }}
            >
              {item.label}
            </Box>
          )}
          value={value}
          onChange={e => setValue(e.target.value)}
          onSelect={value => {
            setValue(value)
            props.onSelect(value)
          }}
        />
      </InputWrapper>
    </>
  )
}

export const UserKPIInput = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [casesData, setCasesData] = useState<CasesData>([])

  useEffect(() => {
    fetch("/cases-minified.json").then(async res => {
      const casesData = await res.json()
      setCasesData(casesData)
    })
  }, [])

  const selectedDataPoint = searchQuery
    ? casesData.find(d => d.search === searchQuery)
    : null

  return (
    <>
      <Box
        bg={theme.colors.bgSuperLight}
        justifyContent="center"
        style={{ overflow: "hidden" }}
      >
        <ContentWrapper maxWidth={"1290px"}>
          <Flex flexWrap="wrap">
            <Flex
              width={[0, 0, 4 / 12, 5 / 12]}
              justifyContent="center"
              alignItems="center"
            >
              <CirclesMap />
            </Flex>

            <Box width={[1, 1, 8 / 12, 7 / 12]} pl={[0, 0, 5]} pb={[6]}>
              <WhatsGoingOnWhereILiveForm
                value={searchQuery}
                onChange={e => {
                  const value = (e && e.target && e.target.value) || ""
                  setSearchQuery(value)
                }}
                onSelect={value => setSearchQuery(value)}
                onClear={() => setSearchQuery("")}
                casesData={casesData}
              />
              {searchQuery && !selectedDataPoint && (
                <Heading mt={[3]}>
                  There is no data available for {searchQuery}. Please try
                  selecting one of the suggested values.{" "}
                </Heading>
              )}
            </Box>
          </Flex>
        </ContentWrapper>
      </Box>
      {searchQuery && selectedDataPoint && (
        <BackgroundWrapper>
          <LocalDetails selectedDataPoint={selectedDataPoint} />
        </BackgroundWrapper>
      )}
    </>
  )
}

const BackgroundWrapper = styled(Box)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    background: linear-gradient(
      90deg,
      #1b4942 50%,
      ${theme.colors.bgLight} 50%
    );
  }
`

const InputWrapper = styled(Box)`
  position: relative;

  input {
    background-color: transparent;
    border-left: none;
    border-top: none;
    border-right: none;
    padding-left: 30px;
    padding-bottom: 5px;
    width: 300px;
  }

  input ~ div {
    margin-top: 10px;
    z-index: 10;
    height: fit-content;

    max-height: 300px !important;
  }
  input:focus {
    outline: none;
    border-bottom: 2px solid ${theme.colors.headingAccent} !important;
  }
  input:active {
    border-bottom: 2px solid ${theme.colors.headingAccent} !important;
  }

  i {
    position: absolute;
    top: 3px;
    left: 5px;
  }

  .ms-SearchBox {
    display: none;
  }

  .ms-ComboBox button {
    display: none;
  }

  .ms-ComboBox-container *::after {
    border-left: none;
    border-top: none;
    border-right: none;
  }
`
