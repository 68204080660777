import * as React from "react"
import { ContentWrapper } from "../ContentWrapper"
import {
  Box,
  Flex,
  Button,
  Heading,
  Text,
  Link,
} from "rebass/styled-components"
import { data } from "./data"
import styled from "styled-components"

export const LinksSection = () => {
  return (
    <ContentWrapper>
      <Grid mt={"100px"} mb={"150px"}>
        {data.linksSection.items.map(page => (
          <>
            <Flex>{page.image}</Flex>
            <Title>{page.title}</Title>
            <Text
              mb={"70px"}
              fontFamily={"Gentona-Light"}
              fontSize={"20px"}
              lineHeight={"30px"}
            >
              {page.description}
            </Text>
            <Box>
              <Button variant="underline" href={page.url} mx={0} as={Link}>
                {page.buttonText}
              </Button>
            </Box>
          </>
        ))}
      </Grid>
    </ContentWrapper>
  )
}

const Grid = styled(Flex)`
  position: relative;
  display: grid;

  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-template-rows:
    minmax(100px, auto)
    minmax(200px, auto)
    minmax(100px, auto)
    minmax(50px, auto);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    grid-column-gap: 150px;
  }
`
const Title = styled(Heading)`
  font-family: Arnhem;
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 70px;
  display: flex;
  align-items: flex-end;
  margin-top: 50px;
  margin-bottom: 50px;
`
