import {
  Flex,
  Box,
  Text,
  Button,
  Heading,
  Link,
} from "rebass/styled-components"
import { SectionSubheading } from "../common/SectionSubheading"
import { data } from "./data"
import * as React from "react"
import { ContentWrapper } from "../ContentWrapper"
import styled from "styled-components"

export const NewsSection = () => (
  <Flex>
    <ContentWrapper>
      <SectionSubheading fontSize={32} mt={75}>
        {data.news.subheading}
      </SectionSubheading>
      <Wrapper flexWrap="wrap" justifyContent="space-between" mx={-5}>
        {data.news.items.map(news => (
          <Box
            width={[1, 1 / 3]}
            mt={"45px"}
            pb={"50px"}
            px={5}
            key={news.title}
          >
            <img src={news.img} style={{ borderRadius: "0px 0px 90px 0px" }} />
            <Heading mb={"25px"} fontFamily={"Gentona-Book"} fontSize={"32px"}>
              {news.title}
            </Heading>
            <Text
              mb={"25px"}
              fontFamily={"Gentona-Light"}
              fontSize={"20px"}
              lineHeight="32px"
            >
              {news.description}
            </Text>
            <Button variant="underline" href={news.url} as={Link}>
              {news.buttonText}
            </Button>
          </Box>
        ))}
      </Wrapper>
    </ContentWrapper>
  </Flex>
)

const Wrapper = styled(Flex)`
  > *:not(:last-child) {
    border-right: 1px solid #dfdfdf;
  }
`
