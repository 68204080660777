import * as React from "react"
import { Hero } from "./Hero"
import { NewsSection } from "./NewsSection"
import { UserKPIInput } from "./UserKPIInput"
import { WhyShouldICare } from "./WhyShouldICare"
import { LinksSection } from "./LinksSection"

export const Homepage = () => {
  return (
    <>
      <Hero />

      <NewsSection />

      <WhyShouldICare />

      <UserKPIInput />

      <LinksSection />
    </>
  )
}
