import { Heading } from "rebass/styled-components"
import * as React from "react"
import { HeadingProps } from "rebass"

export const SectionSubheading = ({
  children,
  ...headingProps
}: {
  children: React.ReactNode
} & HeadingProps) => (
  <Heading fontSize={15} mt={150} fontFamily="Gentona-Book" {...headingProps}>
    {children}
  </Heading>
)
