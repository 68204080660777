import * as React from "react"
import styled from "styled-components"

const ResponsiveCirclesMap = styled.svg`
  position: absolute;
  width: 100%;
  height: 349px;
  opacity: 0.15;
  transform: translate(200px, 0px);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    opacity: 1;
    width: 584px;
    height: 349px;
    transform: translate(-100px, 0px);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 584px;
    height: 349px;
    transform: translate(-30px, 10px);
  }
`

export const CirclesMap = () => (
  <ResponsiveCirclesMap
    viewBox="0 0 584 349"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ maxWidth: "80vw" }}
  >
    <path
      d="M513.335 104.565C517.528 104.565 520.927 107.927 520.927 112.075C520.927 116.222 517.528 119.585 513.335 119.585C509.142 119.585 505.743 116.222 505.743 112.075C505.743 107.927 509.142 104.565 513.335 104.565Z"
      fill="#7D928E"
    />
    <path
      d="M408.216 125.361C412.409 125.361 415.808 128.724 415.808 132.871C415.808 137.019 412.409 140.381 408.216 140.381C404.024 140.381 400.624 137.019 400.624 132.871C400.624 128.724 404.023 125.361 408.216 125.361Z"
      fill="#7D928E"
    />
    <path
      d="M492.311 125.361C496.504 125.361 499.903 128.724 499.903 132.871C499.903 137.019 496.504 140.381 492.311 140.381C488.119 140.381 484.719 137.019 484.719 132.871C484.719 128.724 488.119 125.361 492.311 125.361Z"
      fill="#7D928E"
    />
    <path
      d="M534.36 125.361C538.551 125.361 541.952 128.724 541.952 132.871C541.952 137.019 538.551 140.381 534.36 140.381C530.167 140.381 526.768 137.019 526.768 132.871C526.768 128.724 530.167 125.361 534.36 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M49.6394 20.7975C53.8323 20.7975 57.2315 24.1601 57.2315 28.3078C57.2315 32.4555 53.8323 35.818 49.6394 35.818C45.4466 35.818 42.0474 32.4555 42.0474 28.3078C42.0474 24.1601 45.4466 20.7975 49.6394 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M112.712 20.7975C116.905 20.7975 120.304 24.1601 120.304 28.3078C120.304 32.4555 116.905 35.818 112.712 35.818C108.519 35.818 105.12 32.4555 105.12 28.3078C105.12 24.1601 108.519 20.7975 112.712 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M218.415 20.7975C222.608 20.7975 226.007 24.1601 226.007 28.3078C226.007 32.4555 222.608 35.818 218.415 35.818C214.223 35.818 210.823 32.4555 210.823 28.3078C210.823 24.1601 214.223 20.7975 218.415 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M28.6152 104.565C32.8081 104.565 36.2073 107.927 36.2073 112.075C36.2073 116.222 32.8081 119.585 28.6152 119.585C24.4223 119.585 21.0231 116.222 21.0231 112.075C21.0243 107.927 24.4223 104.565 28.6152 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M28.6152 125.361C32.8081 125.361 36.2073 128.724 36.2073 132.871C36.2073 137.019 32.8081 140.381 28.6152 140.381C24.4223 140.381 21.0231 137.019 21.0231 132.871C21.0231 128.724 24.4223 125.361 28.6152 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M70.6635 146.158C74.8564 146.158 78.2556 149.521 78.2556 153.668C78.2556 157.816 74.8564 161.178 70.6635 161.178C66.4707 161.178 63.0715 157.816 63.0715 153.668C63.0715 149.521 66.4707 146.158 70.6635 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M175.784 146.158C179.977 146.158 183.376 149.521 183.376 153.668C183.376 157.816 179.977 161.178 175.784 161.178C171.591 161.178 168.192 157.816 168.192 153.668C168.192 149.521 171.591 146.158 175.784 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M281.488 146.158C285.681 146.158 289.08 149.521 289.08 153.668C289.08 157.816 285.681 161.178 281.488 161.178C277.296 161.178 273.896 157.816 273.896 153.668C273.896 149.521 277.296 146.158 281.488 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M534.36 146.158C538.551 146.158 541.952 149.521 541.952 153.668C541.952 157.816 538.551 161.178 534.36 161.178C530.167 161.178 526.768 157.816 526.768 153.668C526.768 149.521 530.167 146.158 534.36 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M112.712 187.753C116.905 187.753 120.304 191.116 120.304 195.263C120.304 199.411 116.905 202.774 112.712 202.774C108.519 202.774 105.12 199.411 105.12 195.263C105.12 191.116 108.519 187.753 112.712 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M154.76 187.753C158.952 187.753 162.352 191.116 162.352 195.263C162.352 199.411 158.952 202.774 154.76 202.774C150.567 202.774 147.168 199.411 147.168 195.263C147.168 191.116 150.567 187.753 154.76 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M429.239 187.753C433.432 187.753 436.831 191.116 436.831 195.263C436.831 199.411 433.432 202.774 429.239 202.774C425.048 202.774 421.647 199.411 421.647 195.263C421.647 191.116 425.047 187.753 429.239 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M239.44 208.551C243.632 208.551 247.032 211.914 247.032 216.061C247.032 220.208 243.632 223.572 239.44 223.572C235.247 223.572 231.848 220.208 231.848 216.061C231.848 211.912 235.247 208.551 239.44 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M302.511 229.348C306.704 229.348 310.103 232.711 310.103 236.859C310.103 241.005 306.704 244.369 302.511 244.369C298.318 244.369 294.919 241.005 294.919 236.859C294.919 232.71 298.318 229.348 302.511 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M344.559 250.145C348.751 250.145 352.151 253.507 352.151 257.655C352.151 261.801 348.751 265.165 344.559 265.165C340.367 265.165 336.967 261.801 336.967 257.655C336.967 253.507 340.367 250.145 344.559 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.6"
      d="M492.311 270.942C496.504 270.942 499.903 274.305 499.903 278.453C499.903 282.599 496.504 285.963 492.311 285.963C488.119 285.963 484.719 282.599 484.719 278.453C484.719 274.305 488.119 270.942 492.311 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M260.464 20.7975C264.657 20.7975 268.056 24.1601 268.056 28.3078C268.056 32.4555 264.657 35.818 260.464 35.818C256.271 35.818 252.872 32.4555 252.872 28.3078C252.872 24.1601 256.271 20.7975 260.464 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M323.535 20.7975C327.727 20.7975 331.127 24.1601 331.127 28.3078C331.127 32.4555 327.727 35.818 323.535 35.818C319.342 35.818 315.943 32.4555 315.943 28.3078C315.943 24.1601 319.342 20.7975 323.535 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M344.559 20.7975C348.751 20.7975 352.151 24.1601 352.151 28.3078C352.151 32.4555 348.751 35.818 344.559 35.818C340.367 35.818 336.967 32.4555 336.967 28.3078C336.967 24.1601 340.367 20.7975 344.559 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M302.511 41.5953C306.704 41.5953 310.103 44.9578 310.103 49.1054C310.103 53.2531 306.704 56.6156 302.511 56.6156C298.318 56.6156 294.919 53.2531 294.919 49.1054C294.919 44.9578 298.318 41.5953 302.511 41.5953Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M323.535 41.5953C327.727 41.5953 331.127 44.9578 331.127 49.1054C331.127 53.2531 327.727 56.6156 323.535 56.6156C319.342 56.6156 315.943 53.2531 315.943 49.1054C315.943 44.9578 319.342 41.5953 323.535 41.5953Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M344.559 41.5953C348.751 41.5953 352.151 44.9578 352.151 49.1054C352.151 53.2531 348.751 56.6156 344.559 56.6156C340.367 56.6156 336.967 53.2531 336.967 49.1054C336.967 44.9578 340.367 41.5953 344.559 41.5953Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M365.584 41.5953C369.776 41.5953 373.176 44.9578 373.176 49.1054C373.176 53.2531 369.776 56.6156 365.584 56.6156C361.391 56.6156 357.992 53.2531 357.992 49.1054C357.992 44.9578 361.391 41.5953 365.584 41.5953Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M386.608 41.5953C390.799 41.5953 394.2 44.9578 394.2 49.1054C394.2 53.2531 390.799 56.6156 386.608 56.6156C382.415 56.6156 379.016 53.2531 379.016 49.1054C379.016 44.9578 382.414 41.5953 386.608 41.5953Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M555.384 41.5953C559.575 41.5953 562.976 44.9578 562.976 49.1054C562.976 53.2531 559.575 56.6156 555.384 56.6156C551.191 56.6156 547.792 53.2531 547.792 49.1054C547.792 44.9578 551.19 41.5953 555.384 41.5953Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M154.76 62.9696C158.952 62.9696 162.352 66.3322 162.352 70.4799C162.352 74.6276 158.952 77.9902 154.76 77.9902C150.567 77.9902 147.168 74.6276 147.168 70.4799C147.168 66.3322 150.567 62.9696 154.76 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M239.44 62.9696C243.632 62.9696 247.032 66.3322 247.032 70.4799C247.032 74.6276 243.632 77.9902 239.44 77.9902C235.247 77.9902 231.848 74.6276 231.848 70.4799C231.848 66.3322 235.247 62.9696 239.44 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M302.511 62.9696C306.704 62.9696 310.103 66.3322 310.103 70.4799C310.103 74.6276 306.704 77.9902 302.511 77.9902C298.318 77.9902 294.919 74.6276 294.919 70.4799C294.919 66.3322 298.318 62.9696 302.511 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M323.535 62.9696C327.727 62.9696 331.127 66.3322 331.127 70.4799C331.127 74.6276 327.727 77.9902 323.535 77.9902C319.342 77.9902 315.943 74.6276 315.943 70.4799C315.943 66.3322 319.342 62.9696 323.535 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M344.559 62.9696C348.751 62.9696 352.151 66.3322 352.151 70.4799C352.151 74.6276 348.751 77.9902 344.559 77.9902C340.367 77.9902 336.967 74.6276 336.967 70.4799C336.967 66.3322 340.367 62.9696 344.559 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M365.584 62.9696C369.776 62.9696 373.176 66.3322 373.176 70.4799C373.176 74.6276 369.776 77.9902 365.584 77.9902C361.391 77.9902 357.992 74.6276 357.992 70.4799C357.992 66.3322 361.391 62.9696 365.584 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M386.608 62.9696C390.799 62.9696 394.2 66.3322 394.2 70.4799C394.2 74.6276 390.799 77.9902 386.608 77.9902C382.415 77.9902 379.016 74.6276 379.016 70.4799C379.016 66.3322 382.414 62.9696 386.608 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M534.36 62.9696C538.551 62.9696 541.952 66.3322 541.952 70.4799C541.952 74.6276 538.551 77.9902 534.36 77.9902C530.167 77.9902 526.767 74.6276 526.767 70.4799C526.767 66.3322 530.167 62.9696 534.36 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M555.384 62.9696C559.575 62.9696 562.976 66.3322 562.976 70.4799C562.976 74.6276 559.575 77.9902 555.384 77.9902C551.191 77.9902 547.792 74.6276 547.792 70.4799C547.792 66.3322 551.19 62.9696 555.384 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M91.6877 83.7668C95.8806 83.7668 99.2798 87.1293 99.2798 91.277C99.2798 95.4246 95.8806 98.7872 91.6877 98.7872C87.4949 98.7872 84.0957 95.4246 84.0957 91.277C84.0957 87.1293 87.4949 83.7668 91.6877 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M302.511 83.7668C306.704 83.7668 310.103 87.1293 310.103 91.277C310.103 95.4246 306.704 98.7872 302.511 98.7872C298.318 98.7872 294.919 95.4246 294.919 91.277C294.919 87.1293 298.318 83.7668 302.511 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M323.535 83.7668C327.727 83.7668 331.127 87.1293 331.127 91.277C331.127 95.4246 327.727 98.7872 323.535 98.7872C319.342 98.7872 315.943 95.4246 315.943 91.277C315.943 87.1293 319.342 83.7668 323.535 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M344.559 83.7668C348.751 83.7668 352.151 87.1293 352.151 91.277C352.151 95.4246 348.751 98.7872 344.559 98.7872C340.367 98.7872 336.967 95.4246 336.967 91.277C336.967 87.1293 340.367 83.7668 344.559 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M365.584 83.7668C369.776 83.7668 373.176 87.1293 373.176 91.277C373.176 95.4246 369.776 98.7872 365.584 98.7872C361.391 98.7872 357.992 95.4246 357.992 91.277C357.992 87.1293 361.391 83.7668 365.584 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M386.608 83.7668C390.799 83.7668 394.2 87.1293 394.2 91.277C394.2 95.4246 390.799 98.7872 386.608 98.7872C382.415 98.7872 379.016 95.4246 379.016 91.277C379.016 87.1293 382.414 83.7668 386.608 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M408.216 83.7668C412.409 83.7668 415.808 87.1293 415.808 91.277C415.808 95.4246 412.409 98.7872 408.216 98.7872C404.024 98.7872 400.624 95.4246 400.624 91.277C400.624 87.1293 404.023 83.7668 408.216 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M429.239 83.7668C433.432 83.7668 436.831 87.1293 436.831 91.277C436.831 95.4246 433.432 98.7872 429.239 98.7872C425.048 98.7872 421.647 95.4246 421.647 91.277C421.647 87.1293 425.047 83.7668 429.239 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M492.311 83.7668C496.504 83.7668 499.903 87.1293 499.903 91.277C499.903 95.4246 496.504 98.7872 492.311 98.7872C488.119 98.7872 484.719 95.4246 484.719 91.277C484.719 87.1293 488.119 83.7668 492.311 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M513.335 83.7668C517.528 83.7668 520.927 87.1293 520.927 91.277C520.927 95.4246 517.528 98.7872 513.335 98.7872C509.142 98.7872 505.743 95.4246 505.743 91.277C505.743 87.1293 509.142 83.7668 513.335 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M534.36 83.7668C538.551 83.7668 541.952 87.1293 541.952 91.277C541.952 95.4246 538.551 98.7872 534.36 98.7872C530.167 98.7872 526.767 95.4246 526.767 91.277C526.767 87.1293 530.167 83.7668 534.36 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M281.488 104.565C285.681 104.565 289.08 107.927 289.08 112.075C289.08 116.222 285.681 119.585 281.488 119.585C277.296 119.585 273.896 116.222 273.896 112.075C273.896 107.927 277.296 104.565 281.488 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M323.535 104.565C327.727 104.565 331.127 107.927 331.127 112.075C331.127 116.222 327.727 119.585 323.535 119.585C319.342 119.585 315.943 116.222 315.943 112.075C315.943 107.927 319.342 104.565 323.535 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M344.559 104.565C348.751 104.565 352.151 107.927 352.151 112.075C352.151 116.222 348.751 119.585 344.559 119.585C340.367 119.585 336.967 116.222 336.967 112.075C336.967 107.927 340.367 104.565 344.559 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M365.584 104.565C369.776 104.565 373.176 107.927 373.176 112.075C373.176 116.222 369.776 119.585 365.584 119.585C361.391 119.585 357.992 116.222 357.992 112.075C357.992 107.927 361.391 104.565 365.584 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M386.608 104.565C390.799 104.565 394.2 107.927 394.2 112.075C394.2 116.222 390.799 119.585 386.608 119.585C382.415 119.585 379.016 116.222 379.016 112.075C379.016 107.927 382.414 104.565 386.608 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M408.216 104.565C412.409 104.565 415.808 107.927 415.808 112.075C415.808 116.222 412.409 119.585 408.216 119.585C404.024 119.585 400.624 116.222 400.624 112.075C400.624 107.927 404.023 104.565 408.216 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M429.239 104.565C433.432 104.565 436.831 107.927 436.831 112.075C436.831 116.222 433.432 119.585 429.239 119.585C425.048 119.585 421.647 116.222 421.647 112.075C421.647 107.927 425.047 104.565 429.239 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M471.287 104.565C475.48 104.565 478.879 107.927 478.879 112.075C478.879 116.222 475.48 119.585 471.287 119.585C467.094 119.585 463.695 116.222 463.695 112.075C463.695 107.927 467.094 104.565 471.287 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M492.209 104.535C496.402 104.535 499.801 107.897 499.801 112.045C499.801 116.192 496.402 119.555 492.209 119.555C488.016 119.555 484.617 116.192 484.617 112.045C484.617 107.897 488.016 104.535 492.209 104.535Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M534.36 104.565C538.551 104.565 541.952 107.927 541.952 112.075C541.952 116.222 538.551 119.585 534.36 119.585C530.167 119.585 526.767 116.222 526.767 112.075C526.767 107.927 530.167 104.565 534.36 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M344.559 125.361C348.751 125.361 352.151 128.724 352.151 132.871C352.151 137.019 348.751 140.381 344.559 140.381C340.367 140.381 336.967 137.019 336.967 132.871C336.967 128.724 340.367 125.361 344.559 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M365.584 125.361C369.776 125.361 373.176 128.724 373.176 132.871C373.176 137.019 369.776 140.381 365.584 140.381C361.391 140.381 357.992 137.019 357.992 132.871C357.992 128.724 361.391 125.361 365.584 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M386.608 125.361C390.799 125.361 394.2 128.724 394.2 132.871C394.2 137.019 390.799 140.381 386.608 140.381C382.415 140.381 379.016 137.019 379.016 132.871C379.016 128.724 382.414 125.361 386.608 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M450.264 125.361C454.457 125.361 457.856 128.724 457.856 132.871C457.856 137.019 454.457 140.381 450.264 140.381C446.071 140.381 442.672 137.019 442.672 132.871C442.672 128.724 446.07 125.361 450.264 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M471.287 125.361C475.48 125.361 478.879 128.724 478.879 132.871C478.879 137.019 475.48 140.381 471.287 140.381C467.094 140.381 463.695 137.019 463.695 132.871C463.695 128.724 467.094 125.361 471.287 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M513.335 125.361C517.528 125.361 520.927 128.724 520.927 132.871C520.927 137.019 517.528 140.381 513.335 140.381C509.142 140.381 505.743 137.019 505.743 132.871C505.743 128.724 509.142 125.361 513.335 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M450.264 146.158C454.457 146.158 457.856 149.521 457.856 153.668C457.856 157.816 454.457 161.178 450.264 161.178C446.071 161.178 442.672 157.816 442.672 153.668C442.672 149.521 446.07 146.158 450.264 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M471.287 146.158C475.48 146.158 478.879 149.521 478.879 153.668C478.879 157.816 475.48 161.178 471.287 161.178C467.094 161.178 463.695 157.816 463.695 153.668C463.695 149.521 467.094 146.158 471.287 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M492.311 146.158C496.504 146.158 499.903 149.521 499.903 153.668C499.903 157.816 496.504 161.178 492.311 161.178C488.119 161.178 484.719 157.816 484.719 153.668C484.719 149.521 488.119 146.158 492.311 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M513.335 146.158C517.528 146.158 520.927 149.521 520.927 153.668C520.927 157.816 517.528 161.178 513.335 161.178C509.142 161.178 505.743 157.816 505.743 153.668C505.743 149.521 509.142 146.158 513.335 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M365.584 166.956C369.776 166.956 373.176 170.318 373.176 174.466C373.176 178.614 369.776 181.976 365.584 181.976C361.391 181.976 357.992 178.614 357.992 174.466C357.992 170.318 361.391 166.956 365.584 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M450.264 166.956C454.457 166.956 457.856 170.318 457.856 174.466C457.856 178.614 454.457 181.976 450.264 181.976C446.071 181.976 442.672 178.614 442.672 174.466C442.672 170.318 446.07 166.956 450.264 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M471.287 166.956C475.48 166.956 478.879 170.318 478.879 174.466C478.879 178.614 475.48 181.976 471.287 181.976C467.094 181.976 463.695 178.614 463.695 174.466C463.695 170.318 467.094 166.956 471.287 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M450.264 187.753C454.457 187.753 457.856 191.116 457.856 195.263C457.856 199.411 454.457 202.774 450.264 202.774C446.071 202.774 442.672 199.411 442.672 195.263C442.672 191.116 446.07 187.753 450.264 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.7"
      d="M492.311 292.317C496.504 292.317 499.903 295.681 499.903 299.828C499.903 303.975 496.504 307.338 492.311 307.338C488.119 307.338 484.719 303.975 484.719 299.828C484.719 295.681 488.119 292.317 492.311 292.317Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M49.6394 0C53.8323 0 57.2315 3.36253 57.2315 7.51016C57.2315 11.6578 53.8323 15.0203 49.6394 15.0203C45.4466 15.0203 42.0474 11.6578 42.0474 7.51016C42.0474 3.36253 45.4466 0 49.6394 0Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M70.6635 0C74.8564 0 78.2556 3.36253 78.2556 7.51016C78.2556 11.6578 74.8564 15.0203 70.6635 15.0203C66.4707 15.0203 63.0715 11.6578 63.0715 7.51016C63.0715 3.36253 66.4707 0 70.6635 0Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M91.6877 0C95.8806 0 99.2798 3.36253 99.2798 7.51016C99.2798 11.6578 95.8806 15.0203 91.6877 15.0203C87.4949 15.0203 84.0957 11.6578 84.0957 7.51016C84.0957 3.36253 87.4949 0 91.6877 0Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M112.712 0C116.905 0 120.304 3.36253 120.304 7.51016C120.304 11.6578 116.905 15.0203 112.712 15.0203C108.519 15.0203 105.12 11.6578 105.12 7.51016C105.12 3.36253 108.519 0 112.712 0Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 0C137.928 0 141.327 3.36253 141.327 7.51016C141.327 11.6578 137.928 15.0203 133.735 15.0203C129.542 15.0203 126.143 11.6578 126.143 7.51016C126.143 3.36253 129.543 0 133.735 0Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M323.535 0C327.727 0 331.127 3.36253 331.127 7.51016C331.127 11.6578 327.727 15.0203 323.535 15.0203C319.342 15.0203 315.943 11.6578 315.943 7.51016C315.943 3.36253 319.342 0 323.535 0Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M576.408 0C580.6 0 584 3.36253 584 7.51016C584 11.6578 580.6 15.0203 576.408 15.0203C572.215 15.0203 568.816 11.6578 568.816 7.51016C568.816 3.36253 572.214 0 576.408 0Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M28.6152 20.7975C32.8081 20.7975 36.2073 24.1601 36.2073 28.3077C36.2073 32.4554 32.8081 35.818 28.6152 35.818C24.4223 35.818 21.0231 32.4554 21.0231 28.3077C21.0231 24.1601 24.4223 20.7975 28.6152 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M70.6635 20.7975C74.8564 20.7975 78.2556 24.1601 78.2556 28.3077C78.2556 32.4554 74.8564 35.818 70.6635 35.818C66.4707 35.818 63.0715 32.4554 63.0715 28.3077C63.0715 24.1601 66.4707 20.7975 70.6635 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M91.6877 20.7975C95.8806 20.7975 99.2798 24.1601 99.2798 28.3077C99.2798 32.4554 95.8806 35.818 91.6877 35.818C87.4949 35.818 84.0957 32.4554 84.0957 28.3077C84.0957 24.1601 87.4949 20.7975 91.6877 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 20.7975C137.928 20.7975 141.327 24.1601 141.327 28.3077C141.327 32.4554 137.928 35.818 133.735 35.818C129.542 35.818 126.143 32.4554 126.143 28.3077C126.143 24.1601 129.543 20.7975 133.735 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M154.76 20.7975C158.952 20.7975 162.352 24.1601 162.352 28.3077C162.352 32.4554 158.952 35.818 154.76 35.818C150.567 35.818 147.168 32.4554 147.168 28.3077C147.168 24.1601 150.567 20.7975 154.76 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M175.784 20.7975C179.977 20.7975 183.376 24.1601 183.376 28.3077C183.376 32.4554 179.977 35.818 175.784 35.818C171.591 35.818 168.192 32.4554 168.192 28.3077C168.192 24.1601 171.591 20.7975 175.784 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 20.7975C201.585 20.7975 204.984 24.1601 204.984 28.3077C204.984 32.4554 201.585 35.818 197.392 35.818C193.2 35.818 189.8 32.4554 189.8 28.3077C189.8 24.1601 193.198 20.7975 197.392 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M239.44 20.7975C243.632 20.7975 247.032 24.1601 247.032 28.3077C247.032 32.4554 243.632 35.818 239.44 35.818C235.247 35.818 231.848 32.4554 231.848 28.3077C231.848 24.1601 235.247 20.7975 239.44 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 20.7975C285.681 20.7975 289.08 24.1601 289.08 28.3077C289.08 32.4554 285.681 35.818 281.488 35.818C277.296 35.818 273.896 32.4554 273.896 28.3077C273.896 24.1601 277.296 20.7975 281.488 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 20.7975C306.704 20.7975 310.103 24.1601 310.103 28.3077C310.103 32.4554 306.704 35.818 302.511 35.818C298.318 35.818 294.919 32.4554 294.919 28.3077C294.919 24.1601 298.318 20.7975 302.511 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M365.584 20.7975C369.776 20.7975 373.176 24.1601 373.176 28.3077C373.176 32.4554 369.776 35.818 365.584 35.818C361.391 35.818 357.992 32.4554 357.992 28.3077C357.992 24.1601 361.391 20.7975 365.584 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M555.384 20.7975C559.575 20.7975 562.976 24.1601 562.976 28.3077C562.976 32.4554 559.575 35.818 555.384 35.818C551.191 35.818 547.792 32.4554 547.792 28.3077C547.792 24.1601 551.19 20.7975 555.384 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M576.408 20.7975C580.6 20.7975 584 24.1601 584 28.3077C584 32.4554 580.6 35.818 576.408 35.818C572.215 35.818 568.816 32.4554 568.816 28.3077C568.816 24.1601 572.214 20.7975 576.408 20.7975Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M28.6152 41.5954C32.8081 41.5954 36.2073 44.9579 36.2073 49.1056C36.2073 53.2532 32.8081 56.6157 28.6152 56.6157C24.4223 56.6157 21.0231 53.2532 21.0231 49.1056C21.0231 44.9579 24.4223 41.5954 28.6152 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M49.6394 41.5954C53.8323 41.5954 57.2315 44.9579 57.2315 49.1056C57.2315 53.2532 53.8323 56.6157 49.6394 56.6157C45.4466 56.6157 42.0474 53.2532 42.0474 49.1056C42.0474 44.9579 45.4466 41.5954 49.6394 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M70.6635 41.5954C74.8564 41.5954 78.2556 44.9579 78.2556 49.1056C78.2556 53.2532 74.8564 56.6157 70.6635 56.6157C66.4707 56.6157 63.0715 53.2532 63.0715 49.1056C63.0715 44.9579 66.4707 41.5954 70.6635 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M91.6877 41.5954C95.8806 41.5954 99.2798 44.9579 99.2798 49.1056C99.2798 53.2532 95.8806 56.6157 91.6877 56.6157C87.4949 56.6157 84.0957 53.2532 84.0957 49.1056C84.0957 44.9579 87.4949 41.5954 91.6877 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M112.712 41.5954C116.905 41.5954 120.304 44.9579 120.304 49.1056C120.304 53.2532 116.905 56.6157 112.712 56.6157C108.519 56.6157 105.12 53.2532 105.12 49.1056C105.12 44.9579 108.519 41.5954 112.712 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 41.5954C137.928 41.5954 141.327 44.9579 141.327 49.1056C141.327 53.2532 137.928 56.6157 133.735 56.6157C129.542 56.6157 126.143 53.2532 126.143 49.1056C126.143 44.9579 129.543 41.5954 133.735 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M154.76 41.5954C158.952 41.5954 162.352 44.9579 162.352 49.1056C162.352 53.2532 158.952 56.6157 154.76 56.6157C150.567 56.6157 147.168 53.2532 147.168 49.1056C147.168 44.9579 150.567 41.5954 154.76 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M175.784 41.5954C179.977 41.5954 183.376 44.9579 183.376 49.1056C183.376 53.2532 179.977 56.6157 175.784 56.6157C171.591 56.6157 168.192 53.2532 168.192 49.1056C168.192 44.9579 171.591 41.5954 175.784 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 41.5954C201.585 41.5954 204.984 44.9579 204.984 49.1056C204.984 53.2532 201.585 56.6157 197.392 56.6157C193.2 56.6157 189.8 53.2532 189.8 49.1056C189.8 44.9579 193.198 41.5954 197.392 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 41.5954C222.608 41.5954 226.007 44.9579 226.007 49.1056C226.007 53.2532 222.608 56.6157 218.415 56.6157C214.223 56.6157 210.823 53.2532 210.823 49.1056C210.823 44.9579 214.223 41.5954 218.415 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M239.44 41.5954C243.632 41.5954 247.032 44.9579 247.032 49.1056C247.032 53.2532 243.632 56.6157 239.44 56.6157C235.247 56.6157 231.848 53.2532 231.848 49.1056C231.848 44.9579 235.247 41.5954 239.44 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 41.5954C264.657 41.5954 268.056 44.9579 268.056 49.1056C268.056 53.2532 264.657 56.6157 260.464 56.6157C256.271 56.6157 252.872 53.2532 252.872 49.1056C252.872 44.9579 256.271 41.5954 260.464 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 41.5954C285.681 41.5954 289.08 44.9579 289.08 49.1056C289.08 53.2532 285.681 56.6157 281.488 56.6157C277.296 56.6157 273.896 53.2532 273.896 49.1056C273.896 44.9579 277.296 41.5954 281.488 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M408.216 41.5954C412.409 41.5954 415.808 44.9579 415.808 49.1056C415.808 53.2532 412.409 56.6157 408.216 56.6157C404.024 56.6157 400.624 53.2532 400.624 49.1056C400.624 44.9579 404.023 41.5954 408.216 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M513.335 41.5954C517.528 41.5954 520.927 44.9579 520.927 49.1056C520.927 53.2532 517.528 56.6157 513.335 56.6157C509.142 56.6157 505.743 53.2532 505.743 49.1056C505.743 44.9579 509.142 41.5954 513.335 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M534.36 41.5954C538.551 41.5954 541.952 44.9579 541.952 49.1056C541.952 53.2532 538.551 56.6157 534.36 56.6157C530.167 56.6157 526.768 53.2532 526.768 49.1056C526.768 44.9579 530.167 41.5954 534.36 41.5954Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M28.6152 62.9696C32.8081 62.9696 36.2073 66.3322 36.2073 70.4799C36.2073 74.6276 32.8081 77.9902 28.6152 77.9902C24.4223 77.9902 21.0231 74.6276 21.0231 70.4799C21.0231 66.3322 24.4223 62.9696 28.6152 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M49.6394 62.9696C53.8323 62.9696 57.2315 66.3322 57.2315 70.4799C57.2315 74.6276 53.8323 77.9902 49.6394 77.9902C45.4466 77.9902 42.0474 74.6276 42.0474 70.4799C42.0474 66.3322 45.4466 62.9696 49.6394 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M70.6635 62.9696C74.8564 62.9696 78.2556 66.3322 78.2556 70.4799C78.2556 74.6276 74.8564 77.9902 70.6635 77.9902C66.4707 77.9902 63.0715 74.6276 63.0715 70.4799C63.0715 66.3322 66.4707 62.9696 70.6635 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M91.6877 62.9696C95.8806 62.9696 99.2798 66.3322 99.2798 70.4799C99.2798 74.6276 95.8806 77.9902 91.6877 77.9902C87.4949 77.9902 84.0957 74.6276 84.0957 70.4799C84.0957 66.3322 87.4949 62.9696 91.6877 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M112.712 62.9696C116.905 62.9696 120.304 66.3322 120.304 70.4799C120.304 74.6276 116.905 77.9902 112.712 77.9902C108.519 77.9902 105.12 74.6276 105.12 70.4799C105.12 66.3322 108.519 62.9696 112.712 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 62.9696C137.928 62.9696 141.327 66.3322 141.327 70.4799C141.327 74.6276 137.928 77.9902 133.735 77.9902C129.542 77.9902 126.143 74.6276 126.143 70.4799C126.143 66.3322 129.543 62.9696 133.735 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M175.784 62.9696C179.977 62.9696 183.376 66.3322 183.376 70.4799C183.376 74.6276 179.977 77.9902 175.784 77.9902C171.591 77.9902 168.192 74.6276 168.192 70.4799C168.192 66.3322 171.591 62.9696 175.784 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 62.9696C201.585 62.9696 204.984 66.3322 204.984 70.4799C204.984 74.6276 201.585 77.9902 197.392 77.9902C193.2 77.9902 189.8 74.6276 189.8 70.4799C189.8 66.3322 193.198 62.9696 197.392 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 62.9696C222.608 62.9696 226.007 66.3322 226.007 70.4799C226.007 74.6276 222.608 77.9902 218.415 77.9902C214.223 77.9902 210.823 74.6276 210.823 70.4799C210.823 66.3322 214.223 62.9696 218.415 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 62.9696C264.657 62.9696 268.056 66.3322 268.056 70.4799C268.056 74.6276 264.657 77.9902 260.464 77.9902C256.271 77.9902 252.872 74.6276 252.872 70.4799C252.872 66.3322 256.271 62.9696 260.464 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 62.9696C285.681 62.9696 289.08 66.3322 289.08 70.4799C289.08 74.6276 285.681 77.9902 281.488 77.9902C277.296 77.9902 273.896 74.6276 273.896 70.4799C273.896 66.3322 277.296 62.9696 281.488 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M429.239 62.9696C433.432 62.9696 436.831 66.3322 436.831 70.4799C436.831 74.6276 433.432 77.9902 429.239 77.9902C425.048 77.9902 421.647 74.6276 421.647 70.4799C421.647 66.3322 425.047 62.9696 429.239 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M513.335 62.9696C517.528 62.9696 520.927 66.3322 520.927 70.4799C520.927 74.6276 517.528 77.9902 513.335 77.9902C509.142 77.9902 505.743 74.6276 505.743 70.4799C505.743 66.3322 509.142 62.9696 513.335 62.9696Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M7.59207 83.7668C11.7849 83.7668 15.1841 87.1293 15.1841 91.277C15.1841 95.4246 11.7849 98.7872 7.59207 98.7872C3.3992 98.7872 0 95.4246 0 91.277C0 87.1293 3.39803 83.7668 7.59207 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M28.6152 83.7668C32.8081 83.7668 36.2073 87.1293 36.2073 91.277C36.2073 95.4246 32.8081 98.7872 28.6152 98.7872C24.4223 98.7872 21.0231 95.4246 21.0231 91.277C21.0231 87.1293 24.4223 83.7668 28.6152 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M49.6394 83.7668C53.8323 83.7668 57.2315 87.1293 57.2315 91.277C57.2315 95.4246 53.8323 98.7872 49.6394 98.7872C45.4466 98.7872 42.0474 95.4246 42.0474 91.277C42.0474 87.1293 45.4466 83.7668 49.6394 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M70.6635 83.7668C74.8564 83.7668 78.2556 87.1293 78.2556 91.277C78.2556 95.4246 74.8564 98.7872 70.6635 98.7872C66.4707 98.7872 63.0715 95.4246 63.0715 91.277C63.0715 87.1293 66.4707 83.7668 70.6635 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M112.712 83.7668C116.905 83.7668 120.304 87.1293 120.304 91.277C120.304 95.4246 116.905 98.7872 112.712 98.7872C108.519 98.7872 105.12 95.4246 105.12 91.277C105.12 87.1293 108.519 83.7668 112.712 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 83.7668C137.928 83.7668 141.327 87.1293 141.327 91.277C141.327 95.4246 137.928 98.7872 133.735 98.7872C129.542 98.7872 126.143 95.4246 126.143 91.277C126.143 87.1293 129.543 83.7668 133.735 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M154.76 83.7668C158.952 83.7668 162.352 87.1293 162.352 91.277C162.352 95.4246 158.952 98.7872 154.76 98.7872C150.567 98.7872 147.168 95.4246 147.168 91.277C147.168 87.1293 150.567 83.7668 154.76 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M175.784 83.7668C179.977 83.7668 183.376 87.1293 183.376 91.277C183.376 95.4246 179.977 98.7872 175.784 98.7872C171.591 98.7872 168.192 95.4246 168.192 91.277C168.192 87.1293 171.591 83.7668 175.784 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 83.7668C201.585 83.7668 204.984 87.1293 204.984 91.277C204.984 95.4246 201.585 98.7872 197.392 98.7872C193.2 98.7872 189.8 95.4246 189.8 91.277C189.8 87.1293 193.198 83.7668 197.392 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 83.7668C222.608 83.7668 226.007 87.1293 226.007 91.277C226.007 95.4246 222.608 98.7872 218.415 98.7872C214.223 98.7872 210.823 95.4246 210.823 91.277C210.823 87.1293 214.223 83.7668 218.415 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M239.44 83.7668C243.632 83.7668 247.032 87.1293 247.032 91.277C247.032 95.4246 243.632 98.7872 239.44 98.7872C235.247 98.7872 231.848 95.4246 231.848 91.277C231.848 87.1293 235.247 83.7668 239.44 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 83.7668C264.657 83.7668 268.056 87.1293 268.056 91.277C268.056 95.4246 264.657 98.7872 260.464 98.7872C256.271 98.7872 252.872 95.4246 252.872 91.277C252.872 87.1293 256.271 83.7668 260.464 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 83.7668C285.681 83.7668 289.08 87.1293 289.08 91.277C289.08 95.4246 285.681 98.7872 281.488 98.7872C277.296 98.7872 273.896 95.4246 273.896 91.277C273.896 87.1293 277.296 83.7668 281.488 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M450.264 83.7668C454.457 83.7668 457.856 87.1293 457.856 91.277C457.856 95.4246 454.457 98.7872 450.264 98.7872C446.071 98.7872 442.672 95.4246 442.672 91.277C442.672 87.1293 446.07 83.7668 450.264 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M555.384 83.7668C559.575 83.7668 562.976 87.1293 562.976 91.277C562.976 95.4246 559.575 98.7872 555.384 98.7872C551.191 98.7872 547.792 95.4246 547.792 91.277C547.792 87.1293 551.19 83.7668 555.384 83.7668Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M7.59207 104.565C11.7849 104.565 15.1841 107.927 15.1841 112.075C15.1841 116.222 11.7849 119.585 7.59207 119.585C3.3992 119.585 0 116.222 0 112.075C0 107.927 3.39803 104.565 7.59207 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M49.6394 104.565C53.8323 104.565 57.2315 107.927 57.2315 112.075C57.2315 116.222 53.8323 119.585 49.6394 119.585C45.4466 119.585 42.0474 116.222 42.0474 112.075C42.0474 107.927 45.4466 104.565 49.6394 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M70.6635 104.565C74.8564 104.565 78.2556 107.927 78.2556 112.075C78.2556 116.222 74.8564 119.585 70.6635 119.585C66.4707 119.585 63.0715 116.222 63.0715 112.075C63.0715 107.927 66.4707 104.565 70.6635 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M91.6877 104.565C95.8806 104.565 99.2798 107.927 99.2798 112.075C99.2798 116.222 95.8806 119.585 91.6877 119.585C87.4949 119.585 84.0957 116.222 84.0957 112.075C84.0957 107.927 87.4949 104.565 91.6877 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M112.712 104.565C116.905 104.565 120.304 107.927 120.304 112.075C120.304 116.222 116.905 119.585 112.712 119.585C108.519 119.585 105.12 116.222 105.12 112.075C105.12 107.927 108.519 104.565 112.712 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 104.565C137.928 104.565 141.327 107.927 141.327 112.075C141.327 116.222 137.928 119.585 133.735 119.585C129.542 119.585 126.143 116.222 126.143 112.075C126.143 107.927 129.543 104.565 133.735 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M154.76 104.565C158.952 104.565 162.352 107.927 162.352 112.075C162.352 116.222 158.952 119.585 154.76 119.585C150.567 119.585 147.168 116.222 147.168 112.075C147.168 107.927 150.567 104.565 154.76 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M175.784 104.565C179.977 104.565 183.376 107.927 183.376 112.075C183.376 116.222 179.977 119.585 175.784 119.585C171.591 119.585 168.192 116.222 168.192 112.075C168.192 107.927 171.591 104.565 175.784 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 104.565C201.585 104.565 204.984 107.927 204.984 112.075C204.984 116.222 201.585 119.585 197.392 119.585C193.2 119.585 189.8 116.222 189.8 112.075C189.8 107.927 193.198 104.565 197.392 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 104.565C222.608 104.565 226.007 107.927 226.007 112.075C226.007 116.222 222.608 119.585 218.415 119.585C214.223 119.585 210.823 116.222 210.823 112.075C210.823 107.927 214.223 104.565 218.415 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M239.44 104.565C243.632 104.565 247.032 107.927 247.032 112.075C247.032 116.222 243.632 119.585 239.44 119.585C235.247 119.585 231.848 116.222 231.848 112.075C231.848 107.927 235.247 104.565 239.44 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 104.565C264.657 104.565 268.056 107.927 268.056 112.075C268.056 116.222 264.657 119.585 260.464 119.585C256.271 119.585 252.872 116.222 252.872 112.075C252.872 107.927 256.271 104.565 260.464 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 104.565C306.704 104.565 310.103 107.927 310.103 112.075C310.103 116.222 306.704 119.585 302.511 119.585C298.318 119.585 294.919 116.222 294.919 112.075C294.919 107.927 298.318 104.565 302.511 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M450.264 104.565C454.457 104.565 457.856 107.927 457.856 112.075C457.856 116.222 454.457 119.585 450.264 119.585C446.071 119.585 442.672 116.222 442.672 112.075C442.672 107.927 446.07 104.565 450.264 104.565Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M7.59207 125.361C11.7849 125.361 15.1841 128.724 15.1841 132.871C15.1841 137.019 11.7849 140.381 7.59207 140.381C3.3992 140.381 0 137.019 0 132.871C0 128.724 3.39803 125.361 7.59207 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M49.6394 125.361C53.8323 125.361 57.2315 128.724 57.2315 132.871C57.2315 137.019 53.8323 140.381 49.6394 140.381C45.4466 140.381 42.0474 137.019 42.0474 132.871C42.0474 128.724 45.4466 125.361 49.6394 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M70.6635 125.361C74.8564 125.361 78.2556 128.724 78.2556 132.871C78.2556 137.019 74.8564 140.381 70.6635 140.381C66.4707 140.381 63.0715 137.019 63.0715 132.871C63.0715 128.724 66.4707 125.361 70.6635 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M91.6877 125.361C95.8806 125.361 99.2798 128.724 99.2798 132.871C99.2798 137.019 95.8806 140.381 91.6877 140.381C87.4949 140.381 84.0957 137.019 84.0957 132.871C84.0957 128.724 87.4949 125.361 91.6877 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M112.712 125.361C116.905 125.361 120.304 128.724 120.304 132.871C120.304 137.019 116.905 140.381 112.712 140.381C108.519 140.381 105.12 137.019 105.12 132.871C105.12 128.724 108.519 125.361 112.712 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 125.361C137.928 125.361 141.327 128.724 141.327 132.871C141.327 137.019 137.928 140.381 133.735 140.381C129.542 140.381 126.143 137.019 126.143 132.871C126.143 128.724 129.543 125.361 133.735 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M154.76 125.361C158.952 125.361 162.352 128.724 162.352 132.871C162.352 137.019 158.952 140.381 154.76 140.381C150.567 140.381 147.168 137.019 147.168 132.871C147.168 128.724 150.567 125.361 154.76 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M175.784 125.361C179.977 125.361 183.376 128.724 183.376 132.871C183.376 137.019 179.977 140.381 175.784 140.381C171.591 140.381 168.192 137.019 168.192 132.871C168.192 128.724 171.591 125.361 175.784 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 125.361C201.585 125.361 204.984 128.724 204.984 132.871C204.984 137.019 201.585 140.381 197.392 140.381C193.2 140.381 189.8 137.019 189.8 132.871C189.8 128.724 193.198 125.361 197.392 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 125.361C222.608 125.361 226.007 128.724 226.007 132.871C226.007 137.019 222.608 140.381 218.415 140.381C214.223 140.381 210.823 137.019 210.823 132.871C210.823 128.724 214.223 125.361 218.415 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M239.44 125.361C243.632 125.361 247.032 128.724 247.032 132.871C247.032 137.019 243.632 140.381 239.44 140.381C235.247 140.381 231.848 137.019 231.848 132.871C231.848 128.724 235.247 125.361 239.44 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 125.361C264.657 125.361 268.056 128.724 268.056 132.871C268.056 137.019 264.657 140.381 260.464 140.381C256.271 140.381 252.872 137.019 252.872 132.871C252.872 128.724 256.271 125.361 260.464 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 125.361C285.681 125.361 289.08 128.724 289.08 132.871C289.08 137.019 285.681 140.381 281.488 140.381C277.296 140.381 273.896 137.019 273.896 132.871C273.896 128.724 277.296 125.361 281.488 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 125.361C306.704 125.361 310.103 128.724 310.103 132.871C310.103 137.019 306.704 140.381 302.511 140.381C298.318 140.381 294.919 137.019 294.919 132.871C294.919 128.724 298.318 125.361 302.511 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M323.535 125.361C327.727 125.361 331.127 128.724 331.127 132.871C331.127 137.019 327.727 140.381 323.535 140.381C319.342 140.381 315.943 137.019 315.943 132.871C315.943 128.724 319.342 125.361 323.535 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M429.239 125.361C433.432 125.361 436.831 128.724 436.831 132.871C436.831 137.019 433.432 140.381 429.239 140.381C425.048 140.381 421.647 137.019 421.647 132.871C421.647 128.724 425.047 125.361 429.239 125.361Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M28.6152 146.158C32.8081 146.158 36.2073 149.521 36.2073 153.668C36.2073 157.816 32.8081 161.178 28.6152 161.178C24.4223 161.178 21.0231 157.816 21.0231 153.668C21.0243 149.521 24.4223 146.158 28.6152 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M49.6394 146.158C53.8323 146.158 57.2315 149.521 57.2315 153.668C57.2315 157.816 53.8323 161.178 49.6394 161.178C45.4466 161.178 42.0474 157.816 42.0474 153.668C42.0474 149.521 45.4466 146.158 49.6394 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M91.6877 146.158C95.8806 146.158 99.2798 149.521 99.2798 153.668C99.2798 157.816 95.8806 161.178 91.6877 161.178C87.4949 161.178 84.0957 157.816 84.0957 153.668C84.0957 149.521 87.4949 146.158 91.6877 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M112.712 146.158C116.905 146.158 120.304 149.521 120.304 153.668C120.304 157.816 116.905 161.178 112.712 161.178C108.519 161.178 105.12 157.816 105.12 153.668C105.12 149.521 108.519 146.158 112.712 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 146.158C137.928 146.158 141.327 149.521 141.327 153.668C141.327 157.816 137.928 161.178 133.735 161.178C129.542 161.178 126.143 157.816 126.143 153.668C126.143 149.521 129.543 146.158 133.735 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M154.76 146.158C158.952 146.158 162.352 149.521 162.352 153.668C162.352 157.816 158.952 161.178 154.76 161.178C150.567 161.178 147.168 157.816 147.168 153.668C147.168 149.521 150.567 146.158 154.76 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 146.158C201.585 146.158 204.984 149.521 204.984 153.668C204.984 157.816 201.585 161.178 197.392 161.178C193.2 161.178 189.8 157.816 189.8 153.668C189.8 149.521 193.198 146.158 197.392 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 146.158C222.608 146.158 226.007 149.521 226.007 153.668C226.007 157.816 222.608 161.178 218.415 161.178C214.223 161.178 210.823 157.816 210.823 153.668C210.823 149.521 214.223 146.158 218.415 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M239.44 146.158C243.632 146.158 247.032 149.521 247.032 153.668C247.032 157.816 243.632 161.178 239.44 161.178C235.247 161.178 231.848 157.816 231.848 153.668C231.848 149.521 235.247 146.158 239.44 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 146.158C264.657 146.158 268.056 149.521 268.056 153.668C268.056 157.816 264.657 161.178 260.464 161.178C256.271 161.178 252.872 157.816 252.872 153.668C252.872 149.521 256.271 146.158 260.464 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 146.158C306.704 146.158 310.103 149.521 310.103 153.668C310.103 157.816 306.704 161.178 302.511 161.178C298.318 161.178 294.919 157.816 294.919 153.668C294.919 149.521 298.318 146.158 302.511 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M323.535 146.158C327.727 146.158 331.127 149.521 331.127 153.668C331.127 157.816 327.727 161.178 323.535 161.178C319.342 161.178 315.943 157.816 315.943 153.668C315.943 149.521 319.342 146.158 323.535 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M344.559 146.158C348.751 146.158 352.151 149.521 352.151 153.668C352.151 157.816 348.751 161.178 344.559 161.178C340.367 161.178 336.967 157.816 336.967 153.668C336.967 149.521 340.367 146.158 344.559 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M365.584 146.158C369.776 146.158 373.176 149.521 373.176 153.668C373.176 157.816 369.776 161.178 365.584 161.178C361.391 161.178 357.992 157.816 357.992 153.668C357.992 149.521 361.391 146.158 365.584 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M386.608 146.158C390.799 146.158 394.2 149.521 394.2 153.668C394.2 157.816 390.799 161.178 386.608 161.178C382.415 161.178 379.016 157.816 379.016 153.668C379.016 149.521 382.414 146.158 386.608 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M408.216 146.158C412.409 146.158 415.808 149.521 415.808 153.668C415.808 157.816 412.409 161.178 408.216 161.178C404.024 161.178 400.624 157.816 400.624 153.668C400.624 149.521 404.023 146.158 408.216 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M429.239 146.158C433.432 146.158 436.831 149.521 436.831 153.668C436.831 157.816 433.432 161.178 429.239 161.178C425.048 161.178 421.647 157.816 421.647 153.668C421.647 149.521 425.047 146.158 429.239 146.158Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M28.6152 166.956C32.8081 166.956 36.2073 170.318 36.2073 174.466C36.2073 178.614 32.8081 181.976 28.6152 181.976C24.4223 181.976 21.0231 178.614 21.0231 174.466C21.0243 170.318 24.4223 166.956 28.6152 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M49.6394 166.956C53.8323 166.956 57.2315 170.318 57.2315 174.466C57.2315 178.614 53.8323 181.976 49.6394 181.976C45.4466 181.976 42.0474 178.614 42.0474 174.466C42.0474 170.318 45.4466 166.956 49.6394 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M70.6635 166.956C74.8564 166.956 78.2556 170.318 78.2556 174.466C78.2556 178.614 74.8564 181.976 70.6635 181.976C66.4707 181.976 63.0715 178.614 63.0715 174.466C63.0715 170.318 66.4707 166.956 70.6635 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M91.6877 166.956C95.8806 166.956 99.2798 170.318 99.2798 174.466C99.2798 178.614 95.8806 181.976 91.6877 181.976C87.4949 181.976 84.0957 178.614 84.0957 174.466C84.0957 170.318 87.4949 166.956 91.6877 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M112.712 166.956C116.905 166.956 120.304 170.318 120.304 174.466C120.304 178.614 116.905 181.976 112.712 181.976C108.519 181.976 105.12 178.614 105.12 174.466C105.12 170.318 108.519 166.956 112.712 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 166.956C137.928 166.956 141.327 170.318 141.327 174.466C141.327 178.614 137.928 181.976 133.735 181.976C129.542 181.976 126.143 178.614 126.143 174.466C126.143 170.318 129.543 166.956 133.735 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M154.76 166.956C158.952 166.956 162.352 170.318 162.352 174.466C162.352 178.614 158.952 181.976 154.76 181.976C150.567 181.976 147.168 178.614 147.168 174.466C147.168 170.318 150.567 166.956 154.76 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M175.784 166.956C179.977 166.956 183.376 170.318 183.376 174.466C183.376 178.614 179.977 181.976 175.784 181.976C171.591 181.976 168.192 178.614 168.192 174.466C168.192 170.318 171.591 166.956 175.784 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 166.956C201.585 166.956 204.984 170.318 204.984 174.466C204.984 178.614 201.585 181.976 197.392 181.976C193.2 181.976 189.8 178.614 189.8 174.466C189.8 170.318 193.198 166.956 197.392 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 166.956C222.608 166.956 226.007 170.318 226.007 174.466C226.007 178.614 222.608 181.976 218.415 181.976C214.223 181.976 210.823 178.614 210.823 174.466C210.823 170.318 214.223 166.956 218.415 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M239.44 166.956C243.632 166.956 247.032 170.318 247.032 174.466C247.032 178.614 243.632 181.976 239.44 181.976C235.247 181.976 231.848 178.614 231.848 174.466C231.848 170.318 235.247 166.956 239.44 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 166.956C264.657 166.956 268.056 170.318 268.056 174.466C268.056 178.614 264.657 181.976 260.464 181.976C256.271 181.976 252.872 178.614 252.872 174.466C252.872 170.318 256.271 166.956 260.464 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 166.956C285.681 166.956 289.08 170.318 289.08 174.466C289.08 178.614 285.681 181.976 281.488 181.976C277.296 181.976 273.896 178.614 273.896 174.466C273.896 170.318 277.296 166.956 281.488 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 166.956C306.704 166.956 310.103 170.318 310.103 174.466C310.103 178.614 306.704 181.976 302.511 181.976C298.318 181.976 294.919 178.614 294.919 174.466C294.919 170.318 298.318 166.956 302.511 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M323.535 166.956C327.727 166.956 331.127 170.318 331.127 174.466C331.127 178.614 327.727 181.976 323.535 181.976C319.342 181.976 315.943 178.614 315.943 174.466C315.943 170.318 319.342 166.956 323.535 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M344.559 166.956C348.751 166.956 352.151 170.318 352.151 174.466C352.151 178.614 348.751 181.976 344.559 181.976C340.367 181.976 336.967 178.614 336.967 174.466C336.967 170.318 340.367 166.956 344.559 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M386.608 166.956C390.799 166.956 394.2 170.318 394.2 174.466C394.2 178.614 390.799 181.976 386.608 181.976C382.415 181.976 379.016 178.614 379.016 174.466C379.016 170.318 382.414 166.956 386.608 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M408.216 166.956C412.409 166.956 415.808 170.318 415.808 174.466C415.808 178.614 412.409 181.976 408.216 181.976C404.024 181.976 400.624 178.614 400.624 174.466C400.624 170.318 404.023 166.956 408.216 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M429.239 166.956C433.432 166.956 436.831 170.318 436.831 174.466C436.831 178.614 433.432 181.976 429.239 181.976C425.048 181.976 421.647 178.614 421.647 174.466C421.647 170.318 425.047 166.956 429.239 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M492.311 166.956C496.504 166.956 499.903 170.318 499.903 174.466C499.903 178.614 496.504 181.976 492.311 181.976C488.119 181.976 484.719 178.614 484.719 174.466C484.719 170.318 488.119 166.956 492.311 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M513.335 166.956C517.528 166.956 520.927 170.318 520.927 174.466C520.927 178.614 517.528 181.976 513.335 181.976C509.142 181.976 505.743 178.614 505.743 174.466C505.743 170.318 509.142 166.956 513.335 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M534.36 166.956C538.551 166.956 541.952 170.318 541.952 174.466C541.952 178.614 538.551 181.976 534.36 181.976C530.167 181.976 526.768 178.614 526.768 174.466C526.768 170.318 530.167 166.956 534.36 166.956Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M49.6394 187.753C53.8323 187.753 57.2315 191.116 57.2315 195.264C57.2315 199.411 53.8323 202.774 49.6394 202.774C45.4466 202.774 42.0474 199.411 42.0474 195.264C42.0474 191.116 45.4466 187.753 49.6394 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M70.6635 187.753C74.8564 187.753 78.2556 191.116 78.2556 195.264C78.2556 199.411 74.8564 202.774 70.6635 202.774C66.4707 202.774 63.0715 199.411 63.0715 195.264C63.0715 191.116 66.4707 187.753 70.6635 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M91.6877 187.753C95.8806 187.753 99.2798 191.116 99.2798 195.264C99.2798 199.411 95.8806 202.774 91.6877 202.774C87.4949 202.774 84.0957 199.411 84.0957 195.264C84.0957 191.116 87.4949 187.753 91.6877 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 187.753C137.928 187.753 141.327 191.116 141.327 195.264C141.327 199.411 137.928 202.774 133.735 202.774C129.542 202.774 126.143 199.411 126.143 195.264C126.143 191.116 129.543 187.753 133.735 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M175.784 187.753C179.977 187.753 183.376 191.116 183.376 195.264C183.376 199.411 179.977 202.774 175.784 202.774C171.591 202.774 168.192 199.411 168.192 195.264C168.192 191.116 171.591 187.753 175.784 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 187.753C201.585 187.753 204.984 191.116 204.984 195.264C204.984 199.411 201.585 202.774 197.392 202.774C193.2 202.774 189.8 199.411 189.8 195.264C189.8 191.116 193.198 187.753 197.392 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 187.753C222.608 187.753 226.007 191.116 226.007 195.264C226.007 199.411 222.608 202.774 218.415 202.774C214.223 202.774 210.823 199.411 210.823 195.264C210.823 191.116 214.223 187.753 218.415 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M239.44 187.753C243.632 187.753 247.032 191.116 247.032 195.264C247.032 199.411 243.632 202.774 239.44 202.774C235.247 202.774 231.848 199.411 231.848 195.264C231.848 191.116 235.247 187.753 239.44 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 187.753C264.657 187.753 268.056 191.116 268.056 195.264C268.056 199.411 264.657 202.774 260.464 202.774C256.271 202.774 252.872 199.411 252.872 195.264C252.872 191.116 256.271 187.753 260.464 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 187.753C285.681 187.753 289.08 191.116 289.08 195.264C289.08 199.411 285.681 202.774 281.488 202.774C277.296 202.774 273.896 199.411 273.896 195.264C273.896 191.116 277.296 187.753 281.488 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 187.753C306.704 187.753 310.103 191.116 310.103 195.264C310.103 199.411 306.704 202.774 302.511 202.774C298.318 202.774 294.919 199.411 294.919 195.264C294.919 191.116 298.318 187.753 302.511 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M323.535 187.753C327.727 187.753 331.127 191.116 331.127 195.264C331.127 199.411 327.727 202.774 323.535 202.774C319.342 202.774 315.943 199.411 315.943 195.264C315.943 191.116 319.342 187.753 323.535 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M344.559 187.753C348.751 187.753 352.151 191.116 352.151 195.264C352.151 199.411 348.751 202.774 344.559 202.774C340.367 202.774 336.967 199.411 336.967 195.264C336.967 191.116 340.367 187.753 344.559 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M365.584 187.753C369.776 187.753 373.176 191.116 373.176 195.264C373.176 199.411 369.776 202.774 365.584 202.774C361.391 202.774 357.992 199.411 357.992 195.264C357.992 191.116 361.391 187.753 365.584 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M386.608 187.753C390.799 187.753 394.2 191.116 394.2 195.264C394.2 199.411 390.799 202.774 386.608 202.774C382.415 202.774 379.016 199.411 379.016 195.264C379.016 191.116 382.414 187.753 386.608 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M408.216 187.753C412.409 187.753 415.808 191.116 415.808 195.264C415.808 199.411 412.409 202.774 408.216 202.774C404.024 202.774 400.624 199.411 400.624 195.264C400.624 191.116 404.023 187.753 408.216 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M471.287 187.753C475.48 187.753 478.879 191.116 478.879 195.264C478.879 199.411 475.48 202.774 471.287 202.774C467.094 202.774 463.695 199.411 463.695 195.264C463.695 191.116 467.094 187.753 471.287 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M492.311 187.753C496.504 187.753 499.903 191.116 499.903 195.264C499.903 199.411 496.504 202.774 492.311 202.774C488.119 202.774 484.719 199.411 484.719 195.264C484.719 191.116 488.119 187.753 492.311 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M513.335 187.753C517.528 187.753 520.927 191.116 520.927 195.264C520.927 199.411 517.528 202.774 513.335 202.774C509.142 202.774 505.743 199.411 505.743 195.264C505.743 191.116 509.142 187.753 513.335 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M534.36 187.753C538.551 187.753 541.952 191.116 541.952 195.264C541.952 199.411 538.551 202.774 534.36 202.774C530.167 202.774 526.768 199.411 526.768 195.264C526.768 191.116 530.167 187.753 534.36 187.753Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M49.6394 208.551C53.8323 208.551 57.2315 211.914 57.2315 216.061C57.2315 220.208 53.8323 223.572 49.6394 223.572C45.4466 223.572 42.0474 220.208 42.0474 216.061C42.0474 211.912 45.4466 208.551 49.6394 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M70.6635 208.551C74.8564 208.551 78.2556 211.914 78.2556 216.061C78.2556 220.208 74.8564 223.572 70.6635 223.572C66.4707 223.572 63.0715 220.208 63.0715 216.061C63.0715 211.912 66.4707 208.551 70.6635 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M91.6877 208.551C95.8806 208.551 99.2798 211.914 99.2798 216.061C99.2798 220.208 95.8806 223.572 91.6877 223.572C87.4949 223.572 84.0957 220.208 84.0957 216.061C84.0957 211.912 87.4949 208.551 91.6877 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M112.712 208.551C116.905 208.551 120.304 211.914 120.304 216.061C120.304 220.208 116.905 223.572 112.712 223.572C108.519 223.572 105.12 220.208 105.12 216.061C105.12 211.912 108.519 208.551 112.712 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 208.551C137.928 208.551 141.327 211.914 141.327 216.061C141.327 220.208 137.928 223.572 133.735 223.572C129.542 223.572 126.143 220.208 126.143 216.061C126.143 211.912 129.543 208.551 133.735 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M154.76 208.551C158.952 208.551 162.352 211.914 162.352 216.061C162.352 220.208 158.952 223.572 154.76 223.572C150.567 223.572 147.168 220.208 147.168 216.061C147.168 211.912 150.567 208.551 154.76 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M175.784 208.551C179.977 208.551 183.376 211.914 183.376 216.061C183.376 220.208 179.977 223.572 175.784 223.572C171.591 223.572 168.192 220.208 168.192 216.061C168.192 211.912 171.591 208.551 175.784 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 208.551C201.585 208.551 204.984 211.914 204.984 216.061C204.984 220.208 201.585 223.572 197.392 223.572C193.2 223.572 189.8 220.208 189.8 216.061C189.8 211.912 193.198 208.551 197.392 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 208.551C222.608 208.551 226.007 211.914 226.007 216.061C226.007 220.208 222.608 223.572 218.415 223.572C214.223 223.572 210.823 220.208 210.823 216.061C210.823 211.912 214.223 208.551 218.415 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 208.551C264.657 208.551 268.056 211.914 268.056 216.061C268.056 220.208 264.657 223.572 260.464 223.572C256.271 223.572 252.872 220.208 252.872 216.061C252.872 211.912 256.271 208.551 260.464 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 208.551C285.681 208.551 289.08 211.914 289.08 216.061C289.08 220.208 285.681 223.572 281.488 223.572C277.296 223.572 273.896 220.208 273.896 216.061C273.896 211.912 277.296 208.551 281.488 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 208.551C306.704 208.551 310.103 211.914 310.103 216.061C310.103 220.208 306.704 223.572 302.511 223.572C298.318 223.572 294.919 220.208 294.919 216.061C294.919 211.912 298.318 208.551 302.511 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M323.535 208.551C327.727 208.551 331.127 211.914 331.127 216.061C331.127 220.208 327.727 223.572 323.535 223.572C319.342 223.572 315.943 220.208 315.943 216.061C315.943 211.912 319.342 208.551 323.535 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M344.559 208.551C348.751 208.551 352.151 211.914 352.151 216.061C352.151 220.208 348.751 223.572 344.559 223.572C340.367 223.572 336.967 220.208 336.967 216.061C336.967 211.912 340.367 208.551 344.559 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M365.584 208.551C369.776 208.551 373.176 211.914 373.176 216.061C373.176 220.208 369.776 223.572 365.584 223.572C361.391 223.572 357.992 220.208 357.992 216.061C357.992 211.912 361.391 208.551 365.584 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M386.608 208.551C390.799 208.551 394.2 211.914 394.2 216.061C394.2 220.208 390.799 223.572 386.608 223.572C382.415 223.572 379.016 220.208 379.016 216.061C379.016 211.912 382.414 208.551 386.608 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M408.216 208.551C412.409 208.551 415.808 211.914 415.808 216.061C415.808 220.208 412.409 223.572 408.216 223.572C404.024 223.572 400.624 220.208 400.624 216.061C400.624 211.912 404.023 208.551 408.216 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M429.239 208.551C433.432 208.551 436.831 211.914 436.831 216.061C436.831 220.208 433.432 223.572 429.239 223.572C425.048 223.572 421.647 220.208 421.647 216.061C421.647 211.912 425.047 208.551 429.239 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M450.264 208.551C454.457 208.551 457.856 211.914 457.856 216.061C457.856 220.208 454.457 223.572 450.264 223.572C446.071 223.572 442.672 220.208 442.672 216.061C442.672 211.912 446.07 208.551 450.264 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M471.287 208.551C475.48 208.551 478.879 211.914 478.879 216.061C478.879 220.208 475.48 223.572 471.287 223.572C467.094 223.572 463.695 220.208 463.695 216.061C463.695 211.912 467.094 208.551 471.287 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M492.311 208.551C496.504 208.551 499.903 211.914 499.903 216.061C499.903 220.208 496.504 223.572 492.311 223.572C488.119 223.572 484.719 220.208 484.719 216.061C484.719 211.912 488.119 208.551 492.311 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M513.335 208.551C517.528 208.551 520.927 211.914 520.927 216.061C520.927 220.208 517.528 223.572 513.335 223.572C509.142 223.572 505.743 220.208 505.743 216.061C505.743 211.912 509.142 208.551 513.335 208.551Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M70.6635 229.348C74.8564 229.348 78.2556 232.711 78.2556 236.859C78.2556 241.005 74.8564 244.369 70.6635 244.369C66.4707 244.369 63.0715 241.005 63.0715 236.859C63.0715 232.71 66.4707 229.348 70.6635 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M91.6877 229.348C95.8806 229.348 99.2798 232.711 99.2798 236.859C99.2798 241.005 95.8806 244.369 91.6877 244.369C87.4949 244.369 84.0957 241.005 84.0957 236.859C84.0957 232.71 87.4949 229.348 91.6877 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M112.712 229.348C116.905 229.348 120.304 232.711 120.304 236.859C120.304 241.005 116.905 244.369 112.712 244.369C108.519 244.369 105.12 241.005 105.12 236.859C105.12 232.71 108.519 229.348 112.712 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 229.348C137.928 229.348 141.327 232.711 141.327 236.859C141.327 241.005 137.928 244.369 133.735 244.369C129.542 244.369 126.143 241.005 126.143 236.859C126.143 232.71 129.543 229.348 133.735 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M154.76 229.348C158.952 229.348 162.352 232.711 162.352 236.859C162.352 241.005 158.952 244.369 154.76 244.369C150.567 244.369 147.168 241.005 147.168 236.859C147.168 232.71 150.567 229.348 154.76 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M175.784 229.348C179.977 229.348 183.376 232.711 183.376 236.859C183.376 241.005 179.977 244.369 175.784 244.369C171.591 244.369 168.192 241.005 168.192 236.859C168.192 232.71 171.591 229.348 175.784 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 229.348C201.585 229.348 204.984 232.711 204.984 236.859C204.984 241.005 201.585 244.369 197.392 244.369C193.2 244.369 189.8 241.005 189.8 236.859C189.8 232.71 193.198 229.348 197.392 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 229.348C222.608 229.348 226.007 232.711 226.007 236.859C226.007 241.005 222.608 244.369 218.415 244.369C214.223 244.369 210.823 241.005 210.823 236.859C210.823 232.71 214.223 229.348 218.415 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M239.44 229.348C243.632 229.348 247.032 232.711 247.032 236.859C247.032 241.005 243.632 244.369 239.44 244.369C235.247 244.369 231.848 241.005 231.848 236.859C231.848 232.71 235.247 229.348 239.44 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 229.348C264.657 229.348 268.056 232.711 268.056 236.859C268.056 241.005 264.657 244.369 260.464 244.369C256.271 244.369 252.872 241.005 252.872 236.859C252.872 232.71 256.271 229.348 260.464 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 229.348C285.681 229.348 289.08 232.711 289.08 236.859C289.08 241.005 285.681 244.369 281.488 244.369C277.296 244.369 273.896 241.005 273.896 236.859C273.896 232.71 277.296 229.348 281.488 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M323.535 229.348C327.727 229.348 331.127 232.711 331.127 236.859C331.127 241.005 327.727 244.369 323.535 244.369C319.342 244.369 315.943 241.005 315.943 236.859C315.943 232.71 319.342 229.348 323.535 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M344.559 229.348C348.751 229.348 352.151 232.711 352.151 236.859C352.151 241.005 348.751 244.369 344.559 244.369C340.367 244.369 336.967 241.005 336.967 236.859C336.967 232.71 340.367 229.348 344.559 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M365.584 229.348C369.776 229.348 373.176 232.711 373.176 236.859C373.176 241.005 369.776 244.369 365.584 244.369C361.391 244.369 357.992 241.005 357.992 236.859C357.992 232.71 361.391 229.348 365.584 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M386.608 229.348C390.799 229.348 394.2 232.711 394.2 236.859C394.2 241.005 390.799 244.369 386.608 244.369C382.415 244.369 379.016 241.005 379.016 236.859C379.016 232.71 382.414 229.348 386.608 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M408.216 229.348C412.409 229.348 415.808 232.711 415.808 236.859C415.808 241.005 412.409 244.369 408.216 244.369C404.024 244.369 400.624 241.005 400.624 236.859C400.624 232.71 404.023 229.348 408.216 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M429.239 229.348C433.432 229.348 436.831 232.711 436.831 236.859C436.831 241.005 433.432 244.369 429.239 244.369C425.048 244.369 421.647 241.005 421.647 236.859C421.647 232.71 425.047 229.348 429.239 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M450.264 229.348C454.457 229.348 457.856 232.711 457.856 236.859C457.856 241.005 454.457 244.369 450.264 244.369C446.071 244.369 442.672 241.005 442.672 236.859C442.672 232.71 446.07 229.348 450.264 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M471.287 229.348C475.48 229.348 478.879 232.711 478.879 236.859C478.879 241.005 475.48 244.369 471.287 244.369C467.094 244.369 463.695 241.005 463.695 236.859C463.695 232.71 467.094 229.348 471.287 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M492.311 229.348C496.504 229.348 499.903 232.711 499.903 236.859C499.903 241.005 496.504 244.369 492.311 244.369C488.119 244.369 484.719 241.005 484.719 236.859C484.719 232.71 488.119 229.348 492.311 229.348Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M133.735 250.145C137.928 250.145 141.327 253.507 141.327 257.655C141.327 261.801 137.928 265.165 133.735 265.165C129.542 265.165 126.143 261.801 126.143 257.655C126.143 253.507 129.543 250.145 133.735 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M154.76 250.145C158.952 250.145 162.352 253.507 162.352 257.655C162.352 261.801 158.952 265.165 154.76 265.165C150.567 265.165 147.168 261.801 147.168 257.655C147.168 253.507 150.567 250.145 154.76 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M175.784 250.145C179.977 250.145 183.376 253.507 183.376 257.655C183.376 261.801 179.977 265.165 175.784 265.165C171.591 265.165 168.192 261.801 168.192 257.655C168.192 253.507 171.591 250.145 175.784 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M197.392 250.145C201.585 250.145 204.984 253.507 204.984 257.655C204.984 261.801 201.585 265.165 197.392 265.165C193.2 265.165 189.8 261.801 189.8 257.655C189.8 253.507 193.198 250.145 197.392 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 250.145C222.608 250.145 226.007 253.507 226.007 257.655C226.007 261.801 222.608 265.165 218.415 265.165C214.223 265.165 210.823 261.801 210.823 257.655C210.823 253.507 214.223 250.145 218.415 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M239.44 250.145C243.632 250.145 247.032 253.507 247.032 257.655C247.032 261.801 243.632 265.165 239.44 265.165C235.247 265.165 231.848 261.801 231.848 257.655C231.848 253.507 235.247 250.145 239.44 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 250.145C264.657 250.145 268.056 253.507 268.056 257.655C268.056 261.801 264.657 265.165 260.464 265.165C256.271 265.165 252.872 261.801 252.872 257.655C252.872 253.507 256.271 250.145 260.464 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 250.145C285.681 250.145 289.08 253.507 289.08 257.655C289.08 261.801 285.681 265.165 281.488 265.165C277.296 265.165 273.896 261.801 273.896 257.655C273.896 253.507 277.296 250.145 281.488 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 250.145C306.704 250.145 310.103 253.507 310.103 257.655C310.103 261.801 306.704 265.165 302.511 265.165C298.318 265.165 294.919 261.801 294.919 257.655C294.919 253.507 298.318 250.145 302.511 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M323.535 250.145C327.727 250.145 331.127 253.507 331.127 257.655C331.127 261.801 327.727 265.165 323.535 265.165C319.342 265.165 315.943 261.801 315.943 257.655C315.943 253.507 319.342 250.145 323.535 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M365.584 250.145C369.776 250.145 373.176 253.507 373.176 257.655C373.176 261.801 369.776 265.165 365.584 265.165C361.391 265.165 357.992 261.801 357.992 257.655C357.992 253.507 361.391 250.145 365.584 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M386.608 250.145C390.799 250.145 394.2 253.507 394.2 257.655C394.2 261.801 390.799 265.165 386.608 265.165C382.415 265.165 379.016 261.801 379.016 257.655C379.016 253.507 382.414 250.145 386.608 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M408.216 250.145C412.409 250.145 415.808 253.507 415.808 257.655C415.808 261.801 412.409 265.165 408.216 265.165C404.024 265.165 400.624 261.801 400.624 257.655C400.624 253.507 404.023 250.145 408.216 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M429.239 250.145C433.432 250.145 436.831 253.507 436.831 257.655C436.831 261.801 433.432 265.165 429.239 265.165C425.048 265.165 421.647 261.801 421.647 257.655C421.647 253.507 425.047 250.145 429.239 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M450.264 250.145C454.457 250.145 457.856 253.507 457.856 257.655C457.856 261.801 454.457 265.165 450.264 265.165C446.071 265.165 442.672 261.801 442.672 257.655C442.672 253.507 446.07 250.145 450.264 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M471.287 250.145C475.48 250.145 478.879 253.507 478.879 257.655C478.879 261.801 475.48 265.165 471.287 265.165C467.094 265.165 463.695 261.801 463.695 257.655C463.695 253.507 467.094 250.145 471.287 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M492.311 250.145C496.504 250.145 499.903 253.507 499.903 257.655C499.903 261.801 496.504 265.165 492.311 265.165C488.119 265.165 484.719 261.801 484.719 257.655C484.719 253.507 488.119 250.145 492.311 250.145Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M218.415 270.942C222.608 270.942 226.007 274.305 226.007 278.453C226.007 282.599 222.608 285.963 218.415 285.963C214.223 285.963 210.823 282.599 210.823 278.453C210.823 274.305 214.223 270.942 218.415 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M239.44 270.942C243.632 270.942 247.032 274.305 247.032 278.453C247.032 282.599 243.632 285.963 239.44 285.963C235.247 285.963 231.848 282.599 231.848 278.453C231.848 274.305 235.247 270.942 239.44 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 270.942C264.657 270.942 268.056 274.305 268.056 278.453C268.056 282.599 264.657 285.963 260.464 285.963C256.271 285.963 252.872 282.599 252.872 278.453C252.872 274.305 256.271 270.942 260.464 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 270.942C285.681 270.942 289.08 274.305 289.08 278.453C289.08 282.599 285.681 285.963 281.488 285.963C277.296 285.963 273.896 282.599 273.896 278.453C273.896 274.305 277.296 270.942 281.488 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 270.942C306.704 270.942 310.103 274.305 310.103 278.453C310.103 282.599 306.704 285.963 302.511 285.963C298.318 285.963 294.919 282.599 294.919 278.453C294.919 274.305 298.318 270.942 302.511 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M323.535 270.942C327.727 270.942 331.127 274.305 331.127 278.453C331.127 282.599 327.727 285.963 323.535 285.963C319.342 285.963 315.943 282.599 315.943 278.453C315.943 274.305 319.342 270.942 323.535 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M344.559 270.942C348.751 270.942 352.151 274.305 352.151 278.453C352.151 282.599 348.751 285.963 344.559 285.963C340.367 285.963 336.967 282.599 336.967 278.453C336.967 274.305 340.367 270.942 344.559 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M365.584 270.942C369.776 270.942 373.176 274.305 373.176 278.453C373.176 282.599 369.776 285.963 365.584 285.963C361.391 285.963 357.992 282.599 357.992 278.453C357.992 274.305 361.391 270.942 365.584 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M386.608 270.942C390.799 270.942 394.2 274.305 394.2 278.453C394.2 282.599 390.799 285.963 386.608 285.963C382.415 285.963 379.016 282.599 379.016 278.453C379.016 274.305 382.414 270.942 386.608 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M408.216 270.942C412.409 270.942 415.808 274.305 415.808 278.453C415.808 282.599 412.409 285.963 408.216 285.963C404.024 285.963 400.624 282.599 400.624 278.453C400.624 274.305 404.023 270.942 408.216 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M450.264 270.942C454.457 270.942 457.856 274.305 457.856 278.453C457.856 282.599 454.457 285.963 450.264 285.963C446.071 285.963 442.672 282.599 442.672 278.453C442.672 274.305 446.07 270.942 450.264 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M471.287 270.942C475.48 270.942 478.879 274.305 478.879 278.453C478.879 282.599 475.48 285.963 471.287 285.963C467.094 285.963 463.695 282.599 463.695 278.453C463.695 274.305 467.094 270.942 471.287 270.942Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M260.464 292.317C264.657 292.317 268.056 295.681 268.056 299.828C268.056 303.975 264.657 307.338 260.464 307.338C256.271 307.338 252.872 303.975 252.872 299.828C252.872 295.681 256.271 292.317 260.464 292.317Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 292.317C285.681 292.317 289.08 295.681 289.08 299.828C289.08 303.975 285.681 307.338 281.488 307.338C277.296 307.338 273.896 303.975 273.896 299.828C273.896 295.681 277.296 292.317 281.488 292.317Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 292.317C306.704 292.317 310.103 295.681 310.103 299.828C310.103 303.975 306.704 307.338 302.511 307.338C298.318 307.338 294.919 303.975 294.919 299.828C294.919 295.681 298.318 292.317 302.511 292.317Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M323.535 292.317C327.727 292.317 331.127 295.681 331.127 299.828C331.127 303.975 327.727 307.338 323.535 307.338C319.342 307.338 315.943 303.975 315.943 299.828C315.943 295.681 319.342 292.317 323.535 292.317Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M386.608 292.317C390.799 292.317 394.2 295.681 394.2 299.828C394.2 303.975 390.799 307.338 386.608 307.338C382.415 307.338 379.016 303.975 379.016 299.828C379.016 295.681 382.414 292.317 386.608 292.317Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M513.335 292.317C517.528 292.317 520.927 295.681 520.927 299.828C520.927 303.975 517.528 307.338 513.335 307.338C509.142 307.338 505.743 303.975 505.743 299.828C505.743 295.681 509.142 292.317 513.335 292.317Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M281.488 313.115C285.681 313.115 289.08 316.478 289.08 320.625C289.08 324.773 285.681 328.135 281.488 328.135C277.296 328.135 273.896 324.773 273.896 320.625C273.896 316.478 277.296 313.115 281.488 313.115Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 313.115C306.704 313.115 310.103 316.478 310.103 320.625C310.103 324.773 306.704 328.135 302.511 328.135C298.318 328.135 294.919 324.773 294.919 320.625C294.919 316.478 298.318 313.115 302.511 313.115Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M513.335 313.115C517.528 313.115 520.927 316.478 520.927 320.625C520.927 324.773 517.528 328.135 513.335 328.135C509.142 328.135 505.743 324.773 505.743 320.625C505.743 316.478 509.142 313.115 513.335 313.115Z"
      fill="#7D928E"
    />
    <path
      opacity="0.3"
      d="M302.511 333.912C306.704 333.912 310.103 337.276 310.103 341.422C310.103 345.57 306.704 348.933 302.511 348.933C298.318 348.933 294.919 345.57 294.919 341.422C294.919 337.276 298.318 333.912 302.511 333.912Z"
      fill="#7D928E"
    />
  </ResponsiveCirclesMap>
)
