import * as React from "react"
import { ContentWrapper } from "../ContentWrapper"
import { Heading, Flex, Box, Text } from "rebass/styled-components"
import { data } from "./data"
import styled from "styled-components"
import { theme } from "../../theme"

export const WhyShouldICare = () => {
  return (
    <Flex bg={theme.colors.bgLight}>
      <ContentWrapper maxWidth={"1290px"} pb={["50px", 0]}>
        <Heading fontSize={32} mt={6} mb={["80px"]} fontFamily="Gentona-Book">
          {data.whyShouldICare.heading}
        </Heading>
        <ItemsWrapper flexWrap={"wrap"} justifyContent={"center"}>
          {data.whyShouldICare.items.map(item => (
            <Box
              width={["100%", "475px", "100%", "475px"]}
              mx={["auto", "auto", 0]}
              key={item.title}
            >
              <Item mb={["80px"]}>
                <Title>
                  <span>{item.icon}</span>
                  {item.title}
                </Title>
                <Text fontFamily="Gentona-Light" fontSize="18px" mt={4}>
                  {item.content}
                </Text>
                <Source mb={[0]}>
                  <strong>Source:</strong> {item.source}
                </Source>
              </Item>
            </Box>
          ))}
        </ItemsWrapper>
      </ContentWrapper>
    </Flex>
  )
}

const ItemsWrapper = styled(Flex)`
  max-width: 1024px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap:40px;
    margin-bottom: -80px;
    > * {
      :nth-child(2n) {
        position: relative;
        top: -80px;
      }
    }
    > * {
      :nth-child(3) {
        position: relative;
        top: -40px;
      }
  }

    @media screen and (min-width: ${theme.breakpoints[2]}) {
      grid-column-gap: 70px;
    }
`
const Item = styled(Flex)`
  background: #f8faf4;
  border-radius: 43px;
  padding: 36px;
  position: relative;
  display: flex;
  flex-direction: column;
`

const Title = styled(Heading)`
  font-size: 48px;
  font-family: Arnhem;
  margin-top: 40px;
  margin-bottom: 30px;

  display: flex;
  align-items: center;
  top: -75px;
  position: absolute;
  svg {
    margin-right: 24px;
  }
`

const Source = styled(Box)`
  margin-top: 58px;
  font-size: 14px;
`
